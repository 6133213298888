<template>
  <div>
    <div class="header_holder text-center ma mb-6">
        <div class="rounder_top mb-2">
            <div class="card_header ubuntu_b primer">
                Završi narudžbu
            </div>
        </div>
    </div>
    <div class="text-center"  v-show="!products.length">
      <p><i>Košarica je prazna</i></p>
    </div>
    <div v-show="products.length" class="border_all">
        <div class="input_holder" v-if="poslovnice.length>1&&user_id!=141">
            <v-select
                v-model="poslovnica"
                :items="filteredPoslovnice"
                :search-input.sync="searchPoslovnice"
                item-text="title"
                item-value="id"
                label="Narudžba za poslovnicu"
                dense
                solo
            >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="searchPoslovnice"
                    placeholder="Pretrazi..."
                    solo
                    clearable
                    @click.stop
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <v-row v-if="user_id==141">
          <v-col cols="6">
            <v-text-field
              v-model="search"
              label="pretraži(ime komintenta, adresu ili OIB)"
              v-eager
              dense
              solo
              class="mt-6 check"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <div
              v-if="selSubject!=''"
              class="mt-6"
            >
              Selektiran je: {{selSubject}}
            </div>
          </v-col>
          <v-col cols="12" v-if="vSubjects.length>0" class="subjects">
              <v-row dense v-for="pos in vSubjects" :key="pos.vsub_id">
                  <v-col cols="1">
                      <v-checkbox
                      class="check"
                          @change="select_vsub(pos)"
                      ></v-checkbox>
                  </v-col>
                  <v-col cols="5">{{pos.cBuyer}}</v-col>
                  <v-col cols="4">{{pos.cAddress}}</v-col>
                  <v-col cols="2">{{pos.cVAT}}</v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="vSubjects.length==0 && search.length>=3" class="subjects">
              Pretraga nema rezultate, probajte ponovo
          </v-col>
          <v-col cols="12" v-else-if="vSubjects.length==0 && search.length<3 && search!=''" class="subjects">
              Pretraga će započeti kada imate više od dva karaktera u tražilici
          </v-col>
        </v-row>
        <!--<div class="input_holder border_top">
          <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                v-model="dateFormatted"
                label="Dan isporuke narudžbe"
                readonly
                v-bind="attrs"
                v-on="on"
                solo
                @blur="date = parseDate(dateFormatted)"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                scrollable
                :min="start"
                :max="end"
            >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">Odustani</v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">Potvrda</v-btn>
            </v-date-picker>
          </v-dialog>
        </div>-->
        <div class="input_holder border_top" v-if="user_pm.length==2">
            <span class="radio_naslov">Plaćanje:</span>
            <v-radio-group v-model="pm" row class="radio_input">
                <v-radio label="Virmanom" value="2"></v-radio>
                <v-radio label="Gotovinski" value="1"></v-radio>
            </v-radio-group>
        </div>
        <div class="datum">{{dateFormatted}}</div>
        <div class="datum_btn">
          <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="text_odaberite"
                    :label="text_odaberite"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                    class="date_holder"
                  ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  scrollable
                  first-day-of-week="1"
                  :allowed-dates="dozvoljeniDatumi"
                  :min="start"
                  :max="end"
              >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">Odustani</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">Potvrda</v-btn>
              </v-date-picker>
            </v-dialog>
        </div>
    </div>
    <div class="rounder total_holder mt-8">
        <v-btn :disabled="!products.length&&!enable_checkout" @click="checkout()" class="rounder_bottom submit_btn ubuntu_b" color="secondary">
          Završi narudžbu
          <div v-if="products.length" class="white_circle"><v-img :src="require(`@/assets/img/right_red.png`)" contain></v-img></div>
        </v-btn>
    </div>
    
    <div class="clearfix mt-4 mb-8">
      <div class="float-left">
        <router-link to="/shop" class="linker">
          <v-btn small text>
            <v-icon small class="mr-3">fas fa-arrow-left</v-icon> Nastavi kupovinu
          </v-btn>
        </router-link>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment-timezone'
import { addDays } from '../addDays'
import EventBus from '@/event-bus'
export default {
  data() {
    return {
      timer: null,
      date: null,
      today: null,
      dateFormatted: null,
      modal: false,
      enable_checkout: true,
      pm: null,
      subject: null,
      searchPoslovnice: '',
      selSubject: '',
      text_odaberite:'Odaberite drugi datum isporuke.'
    }
  },
  computed: {
    ...mapState({
      checkoutStatus: state => state.cart.checkoutStatus
    }),
    ...mapGetters({
      products: 'cart/cartProducts',
      ord_date: 'cart/get_ord_date',
      vSubjects: 'cart/get_v_subjects',
      poslovnice: 'users/getPoslovnice',
      group: 'users/getGroup',
      user_pm: 'user/getPaymentMethods',
      userCredentials: 'user/getCredentials',
      time: 'cart/getTime',
      user_id: 'user/userId',
      cart_search: 'cart/getSearch',
      posl: 'users/getPoslovnica',
      poslovnica:null,
    }),
    search: {
      get: function () {
          return this.cart_search
      },
      set: function (newValue) {
          this.$store.dispatch('cart/searchSubjects', {
              search: newValue,
              user_id: this.user_id
          })
      }
    },
    filteredPoslovnice () {
      return this.poslovnice.filter((item) => {
        return item.title!=null && item.title.toLowerCase().includes(this.searchPoslovnice.toLowerCase())
      })
    },
    poslovnica: {
      get() {
        return this.posl
      },
      set(poslovnica) {
        console.log('poslovnica', poslovnica)
        let pos = this.poslovnice.find(p => p.id === poslovnica)
        console.log('pos', pos)
        EventBus.$on('set_poslovnica_2',(data) => {
            console.log('primio',data)
            poslovnica = data
            if(poslovnica!=data){
              this.$store.dispatch('users/setPoslovnica2', data, { root: true })
            }
        })
        if (pos) {
          this.subject = pos.id
          this.$store.dispatch('products/setSubjectPrice', { subject: pos.title, s: true }, { root: true })
        } else {
          this.subject = poslovnica
          let pos = this.vSubjects.find(s => s.comp_id === parseInt(poslovnica))
          
          this.$store.dispatch('products/setSubjectPrice', { subject: pos.cBuyer, s: false }, { root: true })
        }
      },
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    start(){
      return addDays(this.today,(parseInt(this.time.substring(11, 13))<20)?0:1)
    },  
    end(){
      return addDays(this.today,50)
    }
  },
  watch: {
    date () {
      if(!(this.$store.getters['users/getPoslovnice'].length === 1 && this.$store.getters['users/getPoslovnice'][0]['document']!==null&&this.user_type!=5)){
        let now = this.time
        let h = parseInt(now.substring(11, 13))
        if((h>=5&&h<=20)&&this.date === this.today){
          this.$store.commit('message_modal', { title: 'UPOZORENJE', text: 'Poštovani, sve narudžbe za danas trebate poslati do najkasnije 05:00 sati. Zakasnili ste s ovom narudžbom te vam nismo u mogućnosti obećati da ćete naručenu robu i dobiti. Hvala vam na razumijevanju!' }, { root: true })
        }
      }
      this.$store.dispatch('cart/set_ord_date', this.date)
      this.dateFormatted = this.formatDateText(this.date)
    },
    today(){
      this.todayFormatted = this.formatDate(this.today)
    },
  },
  methods: {
    ...mapActions('cart', [
      'addProductToCart',
      'removeProductFromCart',
    ]),
    select_vsub(selectedSubject){
      this.selSubject = selectedSubject.cBuyer+' ( '+ selectedSubject.cAddress +' '+selectedSubject.cVAT+')'
      if(selectedSubject.comp_id == 0){
        EventBus.$on('set_poslovnica',(data) => {
          console.log('primio',data);
          this.poslovnica = data.comp_id
        })
        this.$store.dispatch('emit',{
                end: "cart/setSubject",
                call: "set_subjects_to_user_special",
                parameters: { user_id: this.user_id, vsub_id: selectedSubject.vsub_id},
                response:""
        })
      }else{
        this.poslovnica = selectedSubject.comp_id
      }
      this.search = ''
    },
    dozvoljeniDatumi(val){
      console.log('group',this.group);
      if(![4,6].includes(this.group)){
        return true
      }
      var date = new Date(val)
      const day = date.getDay()
      console.log(day)
      switch(this.group){
        case 4:
          return [1,3,5].includes(day)
        case 6:
          return [5].includes(day)
      }
    },
    checkout () {
      console.log('subject',this.subject)
      if(this.poslovnice.length>1 && this.subject===null){
          this.$store.commit('message_modal', { title: 'Greška', text: 'Molimo Vas odaberite poslovnicu na koju se odnosi narudžba.' }, { root: true })
          return
      }
      let pm = 1
      if(this.user_pm.length===2){
        pm = (this.pm===null)? 2 : this.pm
      }else{
        pm = this.user_pm[0]
      }
      if(!this.user_pm.length){
        pm = 1
      }
      
      let parsed = Date.parse(this.ord_date)
      /*console.log(this.end);
      console.log(this.start);
      console.log(parsed);*/
      console.log(Date.parse(this.start)+' <= '+parsed+' && '+Date.parse(this.end)+' >= '+parsed);
      if(parsed){
        if(Date.parse(this.start) <= parsed && Date.parse(this.end) >= parsed){
          //console.log(this.ord_date+' ok')
        }else{
          this.$store.commit('message_modal', { title: 'Greška', text: 'Datum isporuke nije u redu, molim odaberite drugi.' }, { root: true })
          return
        }
      }else{
          this.$store.commit('message_modal', { title: 'Greška', text: 'Datum isporuke nije u redu, molim odaberite drugi' }, { root: true })
          return
      }
      let date = this.ord_date
      let poslovnica
      if(this.subject==null){
        poslovnica = (this.poslovnice.length>1)? this.subject : this.poslovnice[0].id
      }else{
        poslovnica = this.subject
      }
      this.enable_checkout = false
      //console.log({ pm, date, poslovnica_id: poslovnica})
      this.$store.dispatch('cart/send_order', { pm, date, poslovnica_id: poslovnica})
    },
    formatDateText (date) {
      if (!date) return null
      moment.locale('hr')
      let day_name = moment(date).format('dddd')
      const [year, month, day] = date.split('-')
      return `Narudžba za dan: ${day_name}, ${day}.${month}.${year}.`
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}.${day}.${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  created () {
    /*setTimeout(()=>{
      if(this.poslovnice.length==1&&this.user_pm.length<2){
        this.checkout()
      }
    },1000)*/
    this.date = this.ord_date
    this.today = this.time.substring(0, 10)
  },
  afterCreate(){
    var self = this;
    setTimeout((self)=>{
      self.$store.dispatch('users/setPoslovnica2', self.$store.getters['users/getPoslovnica'], { root: true })
    },1000, self)
    
  },
  beforeCreate(){
    if(this.$store.getters['user/getCredentials'].narucivanje == 0) this.$router.push({ name: 'not_auth' })
    this.$store.dispatch('user/getPM', null, { root: true })
  }
}
</script>
<style scoped>
  .product_chip{
    position:relative;
  }
  .cart_item_total{
    color: #000;
    position:absolute;
    top:-25px;
    width:140%;
    left:-20%;
    text-align: center;
  }
  .product_item{
    position:relative;
    margin-bottom:10px;
  }
  .subjects{
    max-width:calc(100% - 30px);
    background: white;
    margin:0 15px;
  }
  .cart_items_list{
    padding:0;
    margin:0 -10px;
  }
  .total_holder{
    background-color: #F1F1F1;
    box-shadow: 0 4px 3px rgba(0,0,0,.1);
    overflow: hidden;
  }
  .cart_total b{
    font-size: 1.2rem;
  }
  .datum{
    color:red;
    min-height: 40px;
    line-height: 40px;
    background: white;
    border-top: 1px solid #dedede;
    text-align: right;
    font-size: 1.3rem;
    padding-right:10px;
    clear:both;
  }
  .date_holder .v-text-field input{
    text-align:right !important;
  }

.datum_btn{
    background: white;
    border-top: 1px solid #dedede;
    clear:both;
    padding:10px;
  }
  .datum_btn .v-label {
    color: red !important;
    text-align: right;
    width: 100%;
  }
  .shipment_text{
    color:rgb(105, 105, 105);
  }
  .total_text{
    color:rgb(105, 105, 105);
    text-transform:uppercase;
  }
  .total_cart{
    text-align: left;
    padding-right:5px;
  }
  .product_item{
    position:relative;
    min-height:35px;
  }
  .product_text{
    line-height:35px;
    position: relative;
    width:calc(100%-90px);
    display: block;
    margin-right:90px;
  }
  .input_holder{
      background: #FFF;
      min-height: 50px;
      height:auto !important;
  }
  .radio_naslov{
    padding:10px;
    float:left;
    display: block;
    line-height: 32px;
  }
  .radio_input{
    float:left;
    display: block;
    line-height: 32px;
  }
  .v-input--selection-controls{
      margin:0 !important;
  }
</style>
