export function addDays (date, days, i=1) {
    if (!date) return null;
    let [year, month, day] = date.split('-');
    let days_in_month = new Date(parseInt(year), parseInt(month), 0).getDate();
    let res_day = 0;
    month = parseInt(month);
    if(parseInt(day) + days > days_in_month){
        res_day = parseInt(day) + days - days_in_month;
        if(month==12){
            month = 1;
            year = parseInt(year)+1;
        }else{
            month = month+1;
        }
        days_in_month = new Date(parseInt(year), parseInt(month), 0).getDate();
        if(res_day > days_in_month){
            res_day = res_day - days_in_month;
            if(month==12){
                month = 1;
                year = parseInt(year)+1;
            }else{
                month = month+1;
            }
        }
    }else{
        res_day = parseInt(day) + days;
    }
    
    let end = year+'-'+(month<10?'0':'')+month+'-'+(res_day<10?'0':'')+res_day;
    console.log(end);
    console.log(i);
    console.log(Date.parse(end));
    if(isNaN(Date.parse(end))&& i<5)addDays (date, 50+i, i+1);
    return end;
}
